define("egift-frontend/templates/components/gift-info-form/delivery-date-and-quantity-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ijhGffiI",
    "block": "{\"symbols\":[\"hasError\",\"@shouldEnableValidations\",\"@giftInfo\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"field-group__row field-group__column-split\"],[4,[38,0],[[32,0,[\"initialize\"]]],null],[12],[2,\"\\n  \"],[8,\"validation-tooltip\",[],[[\"@enabled\",\"@message\"],[[32,2],[30,[36,1],[[30,[36,1],[[30,[36,1],[[30,[36,1],[[32,3,[\"validator\"]],\"validations\"],null],\"attrs\"],null],\"quantity\"],null],\"message\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"market-stepper\"],[16,0,[31,[\"field-group--full-height \",[30,[36,2],[[32,1],\"text-input--error\"],null]]]],[16,\"max\",100],[16,\"min\",1],[16,3,[30,[36,3],[\"giftInfoForm.quantity\"],null]],[16,2,[32,0,[\"quantity\"]]],[16,\"disabled\",[32,4]],[4,[38,4],[\"marketStepperValueChange\",[32,0,[\"updateQuantity\"]]],null],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[8,\"date-time-picker\",[],[[\"@placeholder\",\"@shouldEnableValidations\",\"@onUpdateDate\",\"@displayDate\",\"@numberOfValidDays\",\"@validationMessage\"],[[30,[36,3],[\"giftInfoForm.deliveryDate\"],null],[32,2],[32,0,[\"updateScheduledDeliveryDate\"]],[32,0,[\"date\"]],60,[30,[36,1],[[30,[36,1],[[30,[36,1],[[30,[36,1],[[32,3,[\"validator\"]],\"validations\"],null],\"attrs\"],null],\"scheduledDeliveryDate\"],null],\"message\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"get\",\"if\",\"t\",\"on\"]}",
    "meta": {
      "moduleName": "egift-frontend/templates/components/gift-info-form/delivery-date-and-quantity-section.hbs"
    }
  });
  _exports.default = _default;
});